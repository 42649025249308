import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Section from "../components/section";
import Img from "gatsby-image";
import SEO from "../components/seo"
import { Container, Box, useTheme, Theme, Typography, Grid, Button, ButtonGroup, Tabs, Tab, useMediaQuery } from "@material-ui/core";
import styled from "@emotion/styled";
import ContentBlock from "../components/contentBlock";
import TestimonialSlider from "../components/testimonialSlider";
import { RoundPaper, StyledMarkdown } from "../components/styledComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrain, faCircle } from '@fortawesome/free-solid-svg-icons';
import MainLayout from "../layouts/mainLayout";
import Hero from "../components/hero";
import LinkButton from "../components/link-button";
import BookBlock from "../composed/book-block";
import Subscribe from "../components/subscribe";
import { ThemeWithAccent } from "../layouts/theme";
import StoreList from "../composed/stores-list";

interface LocationProps {
  location: Location;
}

const BulletIcon = styled(FontAwesomeIcon)`
    margin-right: 12px;
`;

const AccentButton = styled(LinkButton)((props: { theme: ThemeWithAccent }) => `
color: ${props.theme.accent.contrastText};
background-color: ${props.theme.accent.main};
&:hover {
  background-color: ${props.theme.accent.dark || props.theme.accent.main};
}
`);

const IndexPage: React.FC<LocationProps> = (props) => {
  const theme = useTheme<ThemeWithAccent>();
  const phone = useMediaQuery(theme.breakpoints.down("xs"));

  const data = useStaticQuery(graphql`
    query {
      seo: thirtyDayBookJson(block: {eq: "seo" }) {
        title
        description
        image {
          childImageSharp {
              resize(width: 750, height: 300, cropFocus: ENTROPY) {
                  src
                  width
                  height
              }
          }
        }
      }
      header: thirtyDayBookJson(block: {eq: "header"}) {
        title
        tagline
        ctaText
        ctaUrl
        image {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160, maxHeight: 3120) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      testimonials: thirtyDayBookJson(block: {eq: "testimonial" }) {
        quotes {
          quote
          author
          credentials
        }
      }
      book: thirtyDayBookJson(block: {eq: "book"}) {
        title
        tagline
        content
        logoImage {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      bookBlock : homeJson(block: {eq: "book"}){
        bookTeaser
        bookImage {
          childImageSharp {
            fluid(maxHeight: 500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        preOrderImage {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        purchaseTagline
        usStores {
          link
          alt
          img {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const renderItem = (text: string) => <Box display="flex" alignItems="center" py={1}>
    <BulletIcon icon={faCircle} size="2x" color={theme.palette.primary.main} />
    <Typography variant="body1">
      {text}
    </Typography>
  </Box>

  const renderSubItem = (text: string) => <Box px={{xs:3, md:5}}>
    <Box display="flex" alignItems="center">
      <BulletIcon icon={faCircle} size="1x" color={theme.palette.secondary.main} />
      <Typography variant="body1">
        {text}
      </Typography>
    </Box>
  </Box>

  return (
    <MainLayout transparentNav>
      <SEO {...data.seo} location={props.location} />

      <Hero {...data.header} overlayColor="#FFFFFF" heroHeight={phone ? 85 : 85} backgroundPosition="center bottom" />

      {/* <Section>
        <Container maxWidth="md">
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant="h4" align="center">
                <Box pb={2} maxWidth={275 || 175} margin="auto">
                  <Img fluid={(data.book.logoImage.childImageSharp as any).fluid} />
                </Box>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h4" align="center">{data.book.tagline}</Typography>
                </Grid>
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Section> */}

      <Section id="book" tightBottom>
        <BookBlock {...data.bookBlock} />
      </Section>

      <Section id="pre-order-details" tightTop>
        <Container maxWidth="lg">
          <Grid container alignItems="center">
            <Grid item xs={12} sm={3}>
              <Box px={{xs:4,sm:0}}>
                <Img fluid={data.bookBlock.preOrderImage.childImageSharp.fluid} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Typography variant="h4">
                Pre-order The 30-Day Alzheimer’s Solution to access $700 worth of bonus materials, including the 30-Day NEURO Web Course.
            </Typography>
              <Box pt={3} px={{xs:0, md:6}}>
                <Box display="flex" alignItems="center" pb={1}>
                  <BulletIcon icon={faCircle} size="2x" color={theme.palette.primary.main} />
                  <Typography variant="body1">
                    Kick off your 30-days with the Sherzai’s as they personally take you through this transformational journey in their month-long <b>NEURO web course</b>, featuring:
              </Typography>
                </Box>
                {renderSubItem("Starting the Journey Master Class")}
                {renderSubItem("Cognitive assessment before and after the month showing your progress Complete set of educational videos")}
                {renderSubItem("Daily actionable tips")}
                {renderSubItem("Weekly Live Q&A sessions")}
                {renderSubItem("Guest speakers on sleep, stress management, etc.")}
                {renderSubItem("NEURO-certified nutritionists who provide continuous feedback and resources")}
                {renderSubItem("Creating a brain-healthy culture in your home, at work, and in your community")}
                {renderItem("Brain healthy Toolkit - featuring shopping lists, sleep habit do's, and don'ts, 30-minute at home exercise routine, and questions to ask your doctor.")}
                {renderItem("Access to the only clinically tested brain health app -- The NEURO Plan")}
                {renderItem("Access to a Private Facebook Group where we will host cooking nights, live chats, and previews of the book.")}
                {renderItem("A guide to NEURO Plan for Families")}
              </Box>
              <Box textAlign="center" pt={3}>
                <LinkButton url="#pre-order" variant="contained" color="primary"><Typography variant="h6">Pre-Order Today</Typography></LinkButton>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Section>

      <Section bgcolor={theme.palette.primary.light}>
        <Container>
          <Box pb={4}>
            <Typography align="center" variant="h4">What The Experts Are Saying</Typography>
          </Box>
          <TestimonialSlider quotes={data.testimonials.quotes} ></TestimonialSlider>
        </Container>
      </Section>

      <Section id="pre-order">
        <Container maxWidth="md">
          <Box pb={4}>
            <Typography align="center" variant="h4">Two Easy Steps To Claim Your Bonuses!</Typography>
          </Box>
          <Box pb={4}>
            <Typography variant="h5" component="span"><b>Step 1:</b></Typography>
            <Typography variant="h6" component="span"> Pre-order your copy of The 30-Day Alzheimer's Solution from any of the retailers below</Typography>
          </Box>
          <Box pb={4}>
            <StoreList stores={data.bookBlock.usStores} />
          </Box>
          <Box pb={4}>
            <Typography variant="h5" component="span"><b>Step 2:</b></Typography>
            <Typography variant="h6" component="span"> Come back and submit your order information</Typography>
          </Box>
          <Box textAlign="center">
            <AccentButton theme={theme} url="https://mailchi.mp/teamsherzai/pre-order-redemptions" variant="contained" ><Typography variant="h6">Claim your bonuses!</Typography></AccentButton>
          </Box>
        </Container>
      </Section>

      {/* <Section tightTop>
        <Subscribe tagline="Sign Up For Our Team Sherzai Newsletter" cta="Subscribe" />
      </Section> */}
    </MainLayout >
  )
}

export default IndexPage
